<template>
  <div class="">
    <p class="text-text1 font-bold mb-4 heading-4 ">Visits List</p>
      <div class="">
        
        <div class="flex gap-4 flex-wrap justify-between items-center mb-4">
            <div class="flex flex-wrap">
            <div class="" v-for="(status, index) in statusList" :key="index" >
                <StatusBtn class=" m-0 whitespace-nowrap" @buttonAction="toggleActiveAndInactive(status.vistStatusId)" :selected="status.selected" :btnText="status.statusName" :lastInlist="true"/>     
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="pb-10">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="visits_col uppercase  flex items-center">
              <span class="">{{titleObj[0].title}}</span>
            </div>
            <div class="visits_col uppercase flex items-center">
              <span class="">{{titleObj[1].title}}</span>
            </div>
            <div class="visits_col uppercase flex items-center">
              <span class="">{{titleObj[2].title}}</span>
            </div>
            <div class="visits_col uppercase flex items-center">
              <span class="">{{titleObj[3].title}}</span>
            </div>
            <div class="visits_col uppercase flex items-center">
              <span class="">{{titleObj[4].title}}</span>
            </div>
          </div>
          <div class="relative" v-if="visitLIst.length > 0">
            <div v-for="(data, index) in visitLIst" :key="index" @click="showVisitDetail(data.visitId)" class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
              <div class="visits_col">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.visitStartDateTime | dateTimeFilter}}</span>
                </div>
              </div>
              <div class="visits_col">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.visitEndDateTime | dateTimeFilter}}</span>
                </div>
              </div>
              <div class="visits_col">
                <div class="flex items-center">
                <span v-if="data.totalAmount > 0" class="  text-text2 font-bold heading-6">{{data.totalAmount | amountOnlyNumber}}</span>
                <span v-else class="  text-text2 font-bold heading-6">-</span>
                </div>
              </div>
              <div class="visits_col">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.invoiceNumber === '' ? 'No Invoice' : data.invoiceNumber}}</span>
                </div>
              </div>
              <div class="visits_col">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.totalSpendTime === '' ? 'No Time Log' : data.totalSpendTime}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div class=" absolute right-5">
          <Pagination
            :totalDataCount="totalVisitCount"
            :resetPagination="resetPaginationValues"
            :defaultPerPage="5"
            @callApiAsPerPagination="getLeadDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
export default {
  name: "lead",
  components: {
    StatusBtn,
    Pagination,
    NoRecords,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Start Time', icon: '', sortName: 'visitStartDateTime'},
        {title: 'End Time', icon: '', sortName: 'visitEndDateTime'},
        {title: 'Amount', icon: '', sortName: 'totalAmount'},
        {title: 'Invoice', icon: '', sortName: 'invoiceNumber'},
        {title: 'Spend Time', icon: '', sortName: 'totalSpendTime'}
      ],

      statusList: [{
        vistStatusId: 1,
        statusName: 'Action Required',
        value: 'actionRequired',
        selected: false,
      },
      {
        vistStatusId: 2,
        statusName: 'Upcoming',
        value: 'upcoming',
        selected: false,
      },
      {
        vistStatusId: 3,
        statusName: 'Today',
        value: 'today',
        selected: false,
      },
      {
        vistStatusId: 4,
        statusName: 'Late',
        value: 'late',
        selected: false,
      },
      {
        vistStatusId: 5,
        statusName: 'Archived',
        value: 'archived',
        selected: false,
      }
      ],
      visitLIst: [],
      statuValue: '',
      getVisitDebounce: null,
      resetPaginationValues: false,
      isAddNewVisit: false,
      totalVisitCount: 0,
      customerDetailId: 0,
      filterObject: {
        start: 0,
        length: 5,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'visitStartDateTime',
        statusList: []
      },
    };
  },
  props: ["customerId"],
  created() {},
  mounted() {
    document.title = 'Visit List'
    if (this.customerId > 0) {
        this.customerDetailId = this.customerId
    }
    this.getVisitsLIst()
     this.getVisitDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    
    this.$root.$on('addNewVisitHandler', (response) => {
      if (response) {
        this.getVisitsLIst()
      }
      this.isAddNewVisit = false
    })
    this.$root.$on('fromDetailScheduleVisit', (response) => {
      if (response) {
        this.isAddNewVisit = true
      }
    })
  },
  beforeDestroy () {
    this.$root.$off("addNewVisitHandler");
    this.$root.$off("fromDetailScheduleVisit");
  },
  methods: {
    showVisitDetail (visitId) {
      this.$router.push({name: 'visitDetail', params: {orgId: parseInt(this.$route.params.orgId), jobId: parseInt(this.$route.params.jobId), visitId: visitId}})
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getVisitsLIst(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getVisitsLIst(true)
    },
    toggleActiveAndInactive (id) {
        this.statusList.map((data, i) => {
            if (id === data.vistStatusId) {
                 if (this.statusList[i].selected) {
                    this.statuValue = ''
                    this.statusList[i].selected = false
                 } else {
                     this.statuValue = data.value
                    this.statusList[i].selected = true
                 }
            } else {
                this.statusList[i].selected = false
            }
        })
        this.resetPaginationValues = true
        this.getVisitsLIst()
    },
    getVisitsLIst () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.JobVisitsList(
        parseInt(this.$route.params.orgId),
        parseInt(this.$route.params.jobId),
        this.statuValue,
        this.filterObject.start,
        this.filterObject.length,
        '',
          response => {
            this.totalVisitCount = response.count
            this.visitLIst = response.Data !== null ? response.Data : []
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  }
};
</script>
<style scoped>
.visits_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  width: 200px;
}
</style>