<template>
  <div v-if="jobDetails !== null" class="mt-4">
    <div class="card top_primary_card mb-4  bg-primary font-bold text-white p-2 rounded-xl relative w-full">
      <div class=" text-text2 heading-4 absolute left-3 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.go(-1)">
          <i class="fa-solid fa-arrow-left"></i>
        </div>
      <div class="flex justify-center items-center ">
        <h1 class="heading-2 text-center">Job #{{jobDetails.jobNumber}}</h1>
      </div>
    </div>
    <div>
      <div class="lg:grid grid-cols-3 gap-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class="relative">
              <div class="">
                <p class=" font-semibold mb-1 text-gray4 heading-6"><span v-if="jobDetails.isVipJob" class="text-success heading-5 pr-1"><i class="fa-solid fa-crown"></i></span> Job # {{jobDetails.jobNumber}}</p>
                <p class=" font-semibold mb-1 flex items-center">
                  <span class="text-warning heading-5 pr-1"><i class="fa-solid fa-star"></i></span>
                  <span>{{jobDetails.jobTitle}}</span>
                  </p>
                <p v-if="jobDetails.isClosed" class=" text-warning font-semibold mb-1">Closed Job</p>
              </div>
              <div class="block">
                <span class="cursor-pointer text-primary" @click.stop="customerDetail()">{{jobDetails.companyName}}</span>
              <div v-if="jobDetails.customerContactNumber !== ''" class=" flex justify-between mt-2">
                <span>{{jobDetails.customerContactNumber | phone}}</span>
                <div class="flex gap-4">
                  <a class="text-primary" :href="`tel:${jobDetails.customerContactNumber}`"><i class="fa-solid fa-phone-volume"></i></a>
                <a class="text-primary" :href="`sms:${jobDetails.customerContactNumber}`"><i class="fa-solid fa-comment"></i></a>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div  v-if="jobDetails.nextJobVisitDetail.visitStartDateTime !== ''" class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
            <p class="text-text1 font-bold mb-2 heading-4 ">Next Visit</p>
            <div class="cursor-pointer hover:bg-gray1" @click="$router.push({name: 'visitDetail', params: {orgId: parseInt(this.$route.params.orgId), jobId:jobDetails.jobId, visitId: jobDetails.nextJobVisitDetail.visitId}})">
              <p>
                <span v-if="jobDetails.nextJobVisitDetail.dateStatus === 'Late'" class="text-error h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                <span v-if="jobDetails.nextJobVisitDetail.dateStatus === 'Today'" class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                <span v-if="jobDetails.nextJobVisitDetail.dateStatus === 'Upcoming'" class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                {{jobDetails.nextJobVisitDetail.visitStartDateTime | DatePickerDateHeader}} </p>
              <p class="text-gray4 heading-6">
                <span>{{jobDetails.nextJobVisitDetail.visitStartDateTime | DatePickerTimeHeader}}</span> -
                <span>{{jobDetails.nextJobVisitDetail.visitEndDateTime | DatePickerTimeHeader}}</span>
              </p>
            </div>
            </div>
          </div>
          <div  v-if="jobDetails.isUnschedule" class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <p>
              <span class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
              <span class="text-secondary heading-4 pl-2">Unscheduled Job</span>
            </p>
          </div>
          <div v-if="jobDetails.latitude !== 0 && jobDetails.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
              <div class="flex justify-between items-center mb-2">
                  <p class="font-bold">Address</p>
              </div>
              <div class="">
                  <div class="mb-4 flex whitespace-nowrap items-start relative" >
                    <div>
                      <span @click="openMap(jobDetails.latitude, jobDetails.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                    </div>
                    <div class="pl-1">
                        <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-line" v-if="jobDetails.fullAddress === ''">
                        {{jobDetails.addressLine1}}
                        </p>
                        <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-line" v-if="jobDetails.fullAddress !== ''">
                        {{jobDetails.fullAddress}}
                        </p>
                        <p  class="text-text1 heading-6">
                          <span v-if="jobDetails.houseNo !== ''">#: {{jobDetails.houseNo}}</span>
                          <span v-if="jobDetails.houseNo !== ''"> (Floor: {{jobDetails.floorNumber}})</span>
                        </p>
                        <p v-if="jobDetails.buildingName!== ''" class="text-text1 whitespace-pre-line">Bldg: {{jobDetails.buildingName}}</p>
                        <p v-if="jobDetails.directionNote!== ''" class="text-text1 whitespace-pre-line">Directions: {{jobDetails.directionNote}}</p>
                        <p v-if="jobDetails.contactName!== ''" class="text-text1 whitespace-pre-line">Contact: {{jobDetails.contactName}}</p>
                        <p v-if="jobDetails.contactNumber!== ''" class="text-text1 whitespace-pre-line">Phone #: {{jobDetails.contactNumber | phone}}</p>
                    </div>
                  </div>
              </div>
          </div>
          <div v-else class="card bg-white rounded-xl p-4 mb-4">
              <div class="">
                  <div class="flex whitespace-nowrap items-start relative" >
                    <div>
                      <span @click="openMap(orgDetail.latitude, orgDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                    </div>
                    <div class="pl-1">
                      <p class="text-text2 heading-5">(At Office)</p>
                      <div class="">
                        <p class="pr-4 flex flex-wrap text-text2 whitespace-pre-line" v-if="jobDetails.addressLine1 === ''">
                        {{orgDetail.addressLine1}}
                        </p>
                        <p class="pr-4 flex flex-wrap text-text2 whitespace-pre-line" v-if="orgDetail.fullAddress !== ''">
                        {{orgDetail.fullAddress}}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p v-if="jobDetails.jobDescription !== ''" class="  font-bold mb-2">Instructions</p>
              <p v-else class="  font-bold">No Instructions</p>
              <p class="text-gray4 whitespace-pre-line">{{jobDetails.jobDescription}}</p>
            </div>
          </div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p class="  font-bold">Job Type:</p>
              <p class="text-gray4 whitespace-pre-line">{{jobDetails.JobTypeName !== '' ? jobDetails.JobTypeName : '---'}}</p>
              <p class="  font-bold mt-1">Job Estimation:</p>
              <p class="text-gray4 whitespace-pre-line" v-if="jobDetails.jobEstimation !== '' && jobDetails.jobEstimation !== 0">{{setDisplayEstimation(jobDetails.jobEstimation)}}</p>
              <p class="text-gray4 whitespace-pre-line" v-else>---</p>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div  class="card rounded-xl bg-white mb-4 p-4" v-if="!jobDetails.isUnschedule">
            <VisitList />
          </div>
          <div class="card p-4 bg-white rounded-xl mb-4">
            <LineItems :visitDetail="{
              customerId:jobDetails.customerId,
              jobId:jobDetails.jobId,
              visitId:0,
              jobTitle: jobDetails.jobTitle,
              visitNumber: '',
              requestType: 'job',
              }" />
          </div>
          <div  class="card rounded-xl bg-white mb-4 p-4">
            <NotesList :detailId="parseInt(this.$route.params.jobId)" :requestType="'job'" :noteTitle="'Job Note'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var moment = require('moment')
import ADMINAPI from '@/View/Admin/api/Admin.js'
import VisitList from './VisitList.vue';
import NotesList from './NotesList.vue';
import LineItems from './LineItems.vue';

export default {
  name: "lead",
  components: {
    VisitList,
    NotesList,
    LineItems,
  },
  data() {
    return {
      orgDetail: {},
      jobDetails: null
    };
  },
  created() {},
  mounted() {
    document.title = 'Job Detail'
    this.getsJobDetail()
  },
  beforeDestroy() {
  },
  methods: {
    setDisplayEstimation (value) {
      let estimation = value
      let hours = Math.floor(estimation / 60); // Get the whole number of hours
      let minutes = estimation % 60; // Get the remaining minutes
      let formattedTime = `${ hours > 0 ? hours.toString().padStart(2, '0') + ' hr' : ''} ${hours > 0 && minutes > 0 ? "," : ''} ` + ` ${minutes > 0 ? ` ${minutes.toString().padStart(2, '0')} mins` : ''}`;

      return formattedTime
    },
    customerDetail () {
      this.$router.push({name: 'CustomersDetail', params: {customerId: this.jobDetails.customerId}})
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
    getsJobDetail() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      ADMINAPI.JobDetail(
        parseInt(this.$route.params.jobId),
        (response) => {
          this.jobDetails = response.Data;
          let currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          currentDate = new Date(currentDate);
          const cFormated = moment(currentDate).format('YYYY-MM-DD');
          if ( moment(new Date(this.jobDetails.nextJobVisitDetail.visitStartDateTime)).format('YYYY-MM-DD',) === cFormated) {
            this.jobDetails.nextJobVisitDetail.dateStatus = 'Today';
          } else if ( new Date(this.jobDetails.nextJobVisitDetail.visitStartDateTime).getTime() < currentDate.getTime()) {
            this.jobDetails.nextJobVisitDetail.dateStatus = 'Late';
          } else {
            this.jobDetails.nextJobVisitDetail.dateStatus = 'Upcoming';
          }
          if (this.jobDetails.latitude === 0 && this.jobDetails.longitude === 0) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
            ADMINAPI.getOrgDetail(
              parseInt(this.$route.params.orgId),
              (response) => {
                this.orgDetail = response.Data
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              },
              (err) => {
                this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              }
            );
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    }
  }
};
</script>
<style scoped>
</style>